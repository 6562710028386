import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';

import Link from '~components/Link';

import AlephBetaLogo from '~images/navbar/aleph-beta-logo-white.png';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logoImage: {
    display: 'flex',
    maxWidth: '100%',
    height: theme.spacing(4)
  }
}));

const Topbar = ({ onSidebarOpen, className, ...rest }) => {
  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar {...rest} className={classes.root}>
      <Toolbar>
        <Link to="/">
          <img alt="Logo" src={AlephBetaLogo} className={classes.logoImage} />
        </Link>

        <div className={classes.flexGrow} />

        <Hidden mdDown>
          <IconButton aria-label="notifications" color="inherit">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton aria-label="input" className={classes.signOutButton} color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden>

        <Hidden lgUp>
          <IconButton aria-label="menu" color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func.isRequired
};

Topbar.defaultProps = {
  className: null
};

export default Topbar;
