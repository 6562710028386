import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import DashboardIcon from '@material-ui/icons/Dashboard';
import UsersIcon from '@material-ui/icons/People';
import PopupsIcon from '@material-ui/icons/PictureInPicture';
import CommentIcon from '@material-ui/icons/Comment';
import InfoIcon from '@material-ui/icons/Info';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BrokenImage from '@material-ui/icons/BrokenImage';

import Profile from './components/Profile';
import SidebarNav from './components/SidebarNav';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/admin',
      icon: <DashboardIcon />
    },
    {
      title: 'Users',
      href: '/admin/users',
      icon: <UsersIcon />
    },
    {
      title: 'Popups',
      href: '/admin/popups',
      icon: <PopupsIcon />
    },
    {
      title: 'Comments',
      href: '/admin/comments',
      icon: <CommentIcon />
    },
    {
      title: 'Video Information',
      href: '/admin/video-information',
      icon: <InfoIcon />
    },
    {
      title: 'Statistics',
      href: '/admin/stats',
      icon: <AssessmentIcon />
    },
    {
      title: 'Videos Report',
      href: '/admin/video-report',
      icon: <AssessmentIcon />
    },
    {
      title: 'Cohorts',
      href: '/admin/cohorts',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Content Report',
      href: '/content-report-static',
      icon: <AssessmentIcon />
    },
    {
      title: 'Broken Subscription',
      href: '/admin/broken-subscription',
      icon: <BrokenImage />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={classes.root}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

Sidebar.defaultProps = {
  className: null
};

export default Sidebar;
