import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import Link from '~components/Link';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import DefaultAuthorImage from '~images/authors/default-avatar.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    textAlign: 'center',
    marginTop: theme.spacing(1)
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { fullName } = session;
  return (
    <div {...rest} className={classNames(classes.root, className)}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={Link}
        src={DefaultAuthorImage}
        to="/account"
      />
      <Typography className={classes.name} variant="h4">
        {fullName}
      </Typography>
      <Typography variant="body2">Admin</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

Profile.defaultProps = {
  className: ''
};

export default Profile;
